import { useLocation, useNavigate } from 'react-router-dom'

export const useUpdateURLParameters = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const updateURLParameters = data => {
    const currentQueryParams = new URLSearchParams(location.search)
    const newQueryParams = new URLSearchParams(currentQueryParams.toString())

    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        const uniqueValues = [...new Set(data[key])]
        newQueryParams.set(key, uniqueValues.join(','))
      } else if (typeof data[key] === 'string' && data[key].trim() !== '') {
        newQueryParams.set(key, data[key])
      } else {
        newQueryParams.delete(key)
      }
    })

    navigate(`?${newQueryParams.toString()}`)
  }

  const currentCategories = new URLSearchParams(location.search)
    .getAll('kategoria')
    .flatMap(pt => pt.split(','))
  const currentColors = new URLSearchParams(location.search)
    .getAll('ngjyra')
    .flatMap(color => color.split(','))
  const currentMaterials = new URLSearchParams(location.search)
    .getAll('materiali')
    .flatMap(material => material.split(','))
  const currentQuery = new URLSearchParams(location.search).get('q')

  return {
    currentColors,
    currentMaterials,
    currentQuery,
    currentCategories,
    updateURLParameters,
  }
}
