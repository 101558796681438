import React from 'react'
import HomeCarousel from '../components/homeComponents/HomeCarousel'

const Home = () => {
  //start
  return (
    <div>
      <HomeCarousel />
    </div>
  )
}

export default Home
