import {
  GREEN_COLOR,
  SEMI_TRANSPARENT_GREEN,
  WHITE_COLOR,
} from '../../util/constants'

export const isIdPartOfUrl = (id, currentCategories) => {
  return currentCategories.includes(id)
}

export const getColor = (id, currentCategories, subcategories) => {
  const isIdInUrl = isIdPartOfUrl(id, currentCategories)
  const areAllSubcategoriesSelected =
    subcategories &&
    subcategories.every(sub => currentCategories.includes(sub._id))
  const isAnySubcategorySelected =
    subcategories &&
    subcategories.some(sub => currentCategories.includes(sub._id))

  if (isIdInUrl || areAllSubcategoriesSelected) {
    return GREEN_COLOR
  }
  if (isAnySubcategorySelected) {
    return SEMI_TRANSPARENT_GREEN
  }
  return WHITE_COLOR
}

export const getModifiedCategories = rawCategories => {
  const categories = new Map()
  const mainCategories = [
    'Artikuj Kuzhine',
    'Artikuj Mirembajtje',
    'Artikuj Pastrimi',
    'Artikuj Tualeti',
    'Artikuj per Gatim',
    'Artikuj Garderobe',
    'Artikuj per servirje',
  ]
  const kitchenCategories = [
    'Filxhane',
    'Gota',
    'Hinke',
    'Pjata',
    'Tasa',
    'Tenxhere',
    'Tepsi',
    'Tigane',
    'Frutjere',
    'Kuti Kafeje',
    'Kripore',
    'Mbulese Tavoline',
    'Thike',
    'Forma Akulli/Biskota',
  ]
  const maintenanceCategories = ['Pecete']
  const cleaningCategories = [
    'Kova',
    'Shtupe',
    'Fshese',
    'Pecete',
    'Kosh',
    'Rule Tapeti',
    'Lopate',
    'Tel/Sfungjer Enesh',
    'Mbajtese Pjatash',
    'Gomine',
  ]
  const toiletCategories = [
    'Kosh Rrobash',
    'Tapete Dere dhe Tualeti',
    'Furce Tualeti',
    'Mbajtese Sapuni',
  ]
  const cookingCategories = [
    'Kullesa',
    'Qeruse',
    'Rrahese',
    'Hinke',
    'Spatul/Luge/Garuzhde',
    'Xhezve',
    'Pllake Perimesh',
    'Rul Pete',
    'Hapese Vere',
    'Site Mielli',
    'Kanister',
    'Tundese Dhalli',
  ]
  const servingCategories = ['Pjata', 'Tabaka', 'Servise Tavoline', 'Xhezve']
  const wardrobeCategories = ['Varese', 'Varese Me Ngjitje', 'Kapese Rrobash']

  rawCategories
    .sort((a, b) => {
      const nameA = a.name.toLowerCase()
      const nameB = b.name.toLowerCase()

      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .forEach(category => {
      if (mainCategories.includes(category.name)) {
        categories.set(category.name, {
          ...category,
          subcategories: [],
        })
      } else if (kitchenCategories.includes(category.name)) {
        categories.set('Artikuj Kuzhine', {
          ...categories.get('Artikuj Kuzhine'),
          subcategories: [
            ...categories.get('Artikuj Kuzhine').subcategories,
            category,
          ],
        })
      } else if (maintenanceCategories.includes(category.name)) {
        categories.set('Artikuj Mirembajtje', {
          ...categories.get('Artikuj Mirembajtje'),
          subcategories: [
            ...categories.get('Artikuj Mirembajtje').subcategories,
            category,
          ],
        })
      } else if (cleaningCategories.includes(category.name)) {
        categories.set('Artikuj Pastrimi', {
          ...categories.get('Artikuj Pastrimi'),
          subcategories: [
            ...categories.get('Artikuj Pastrimi').subcategories,
            category,
          ],
        })
      } else if (toiletCategories.includes(category.name)) {
        categories.set('Artikuj Tualeti', {
          ...categories.get('Artikuj Tualeti'),
          subcategories: [
            ...categories.get('Artikuj Tualeti').subcategories,
            category,
          ],
        })
      } else if (cookingCategories.includes(category.name)) {
        categories.set('Artikuj per Gatim', {
          ...categories.get('Artikuj per Gatim'),
          subcategories: [
            ...categories.get('Artikuj per Gatim').subcategories,
            category,
          ],
        })
      } else if (servingCategories.includes(category.name)) {
        categories.set('Artikuj per servirje', {
          ...categories.get('Artikuj per servirje'),
          subcategories: [
            ...categories.get('Artikuj per servirje').subcategories,
            category,
          ],
        })
      } else if (wardrobeCategories.includes(category.name)) {
        categories.set('Artikuj Garderobe', {
          ...categories.get('Artikuj Garderobe'),
          subcategories: [
            ...categories.get('Artikuj Garderobe').subcategories,
            category,
          ],
        })
      } else {
        categories.set(category.name, category)
      }
    })

  return Array.from(categories.values())
}
