import { useState, useEffect } from 'react'
import api from '../api'

// Create a cache object outside of the hook
const cache = {}

const useFetch = (url, params = {}) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const cacheKey =
    url +
    (params.materials || '') +
    (params.colors || '') +
    (params.categories || '') +
    (params.query || '') +
    (params.limit || '') +
    (params.page || '')

  useEffect(() => {
    const fetchData = async () => {
      if (cache[cacheKey]) {
        setData(cache[cacheKey])
        setLoading(false)
        return
      }

      setLoading(true)
      try {
        const res = await api.get(url, {
          params: {
            materials: params.materials,
            colors: params.colors,
            categories: params.categories,
            query: params.query,
            page: params.page,
            limit: params.limit,
          },
        })
        setData(res.data)
        setError(null)
        // Save the data to the cache
        cache[cacheKey] = res.data
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [
    url,
    params.materials,
    params.colors,
    params.categories,
    params.query,
    params.limit,
    params.page,
    cacheKey,
  ])

  return { data, loading, error }
}

export default useFetch
