import React from 'react'

const About = () => {
  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 py-12 px-6 lg:px-24">
      <div className="max-w-7xl text-gray-800">
        <h1 className="text-4xl font-extrabold text-center mb-6 lg:text-5xl">
          Rreth ARBIN-06
        </h1>

        <p className="text-lg leading-relaxed mb-6 lg:text-xl lg:leading-loose">Në ARBIN-06, ne jemi krenarë që jemi një furnizues i besueshëm i artikujve te shumëllojshme me cilësi të lartë, duke ofruar një gamë të larmishme produktesh në supermarkete të të gjitha madhësive. Me një rrjet global partnerësh dhe prodhuesish, kryesisht në Itali, Kinë dhe Turqi, ne sigurojmë që klientët tanë të marrin më të mirën si në cilësi ashtu edhe në vlerë. Katalogu ynë i gjerë i produkteve përfshin gjithçka, nga gjërat thelbësore të kuzhinës deri te zgjidhjet e pastrimit të shtëpisë, të dizajnuara për të përmbushur nevojat në zhvillim të konsumatorëve të sotëm. Pavarësisht nëse jeni një dyqan i vogël i pavarur ose një zinxhir i madh shitjesh me pakicë, ne kemi ekspertizën e produkteve dhe zinxhirit të furnizimit për të mbështetur biznesin tuaj.</p>
        <h2 className="text-3xl font-bold text-gray-900 mb-4 lg:text-4xl">
          Angazhimi ndaj cilësisë
        </h2>
        <p className="text-lg leading-relaxed mb-6 lg:text-xl lg:leading-loose">Angazhimi ynë për cilësinë fillon me burime rigoroze. Ne kemi ndërtuar marrëdhënie të forta dhe afatgjata me furnitorët tanë, duke zgjedhur me kujdes prodhues të njohur kualitetin e produktit dhe prodhimin e besueshëm. Kjo na lejon të ofrojmë vazhdimisht çmime konkurruese pa sakrifikuar standardet e larta që klientët tanë presin. Me dekada përvojë në industri, ne e kuptojmë rëndësinë e dërgesave në kohë, niveleve të besueshme të stoqeve dhe shërbimit të personalizuar ndaj klientit—faktorë që janë thelbësorë për mbarëvajtjen e shitjeve me pakicë.</p>
        <h2 className="text-3xl font-bold text-gray-900 mb-4 lg:text-4xl">
          Partnerë në sukses
        </h2>
        <p className="text-lg leading-relaxed mb-6 lg:text-xl lg:leading-loose">Në ARBIN-06, ne shkojmë përtej furnizimit të thjeshtë të produkteve; ne bashkëpunojmë me ju për të siguruar suksesin tuaj. Duke ofruar zgjidhje fleksibël dhe të shkallëzuar, ne ndihmojmë bizneset të optimizojnë inventarin e tyre dhe t'u përgjigjen shpejt kërkesave të tregut. Kur na zgjidhni ne, ju po zgjidhni një furnizues të përkushtuar ndaj besueshmërisë, integritetit dhe rritjes, duke siguruar që biznesi juaj të lulëzojë në tregun konkurrues të sotëm.</p>
        
        {/*<div className="text-center mt-12">
          <button className="px-8 py-4 bg-blue-600 text-white text-lg rounded-full shadow-lg hover:bg-blue-500 hover:shadow-xl transition-all">
            Na Kontaktoni
          </button>
        </div>*/}
      </div>
    </div>
  );
};

export default About;
