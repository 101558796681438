import React from 'react'
import { useUpdateURLParameters } from '../../hooks/useUpdateURLParams'
import { getColor } from './_helpers'

const SubCategory = ({ id, title, isChecked, onChange }) => {
  const { currentCategories } = useUpdateURLParameters()

  function handleClick() {
    const newChecked = !isChecked
    if (newChecked) {
      onChange(id, true)
    } else {
      onChange(id, false)
    }
  }

  return (
    <div
      key={id}
      className="my-2 flex cursor-pointer flex-row items-center justify-between hover:bg-gray-100"
      onClick={handleClick}>
      <p>{title}</p>
      <button className="h-[20px] w-[20px]">
        <svg
          viewBox="-102.4 -102.4 1228.80 1228.80"
          className="icon"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
          transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
          stroke="#000000"
          strokeWidth="0.01024">
          <g
            id="SVGRepo_bgCarrier"
            strokeWidth="0"
            transform="translate(15.360000000000014,15.360000000000014), scale(0.97)">
            <rect
              x="-102.4"
              y="-102.4"
              width="1228.80"
              height="1228.80"
              rx="614.4"
              fill="#ffffff"
              stroke="#000000"
              strokeWidth="10"></rect>
          </g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#CCCCCC"
            strokeWidth="4.096"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M866.133333 258.133333L362.666667 761.6l-204.8-204.8L98.133333 618.666667 362.666667 881.066667l563.2-563.2z"
              fill={getColor(id, currentCategories)}></path>
          </g>
        </svg>
      </button>
    </div>
  )
}

export default SubCategory
