import React, { useState } from 'react'
import SubCategory from './SubCategory'
import classNames from 'classnames'
import { useUpdateURLParameters } from '../../hooks/useUpdateURLParams'
import { getColor, isIdPartOfUrl } from './_helpers'
const Category = ({ id, title, subcategories }) => {
  const { currentCategories, updateURLParameters } = useUpdateURLParameters()
  const [isOpen, setIsOpen] = useState(false)
  const isChecked = isIdPartOfUrl(id, currentCategories)

  function handleTitleClick() {
    setIsOpen(prevOpen => !prevOpen)
    if (!subcategories) {
      handleChecking()
    }
  }

  function handleButtonClick(event) {
    event.stopPropagation()
    handleChecking()
  }

  function handleChecking() {
    const newChecked = !isChecked
    const ids = [
      id,
      ...(subcategories ? subcategories.map(sub => sub._id) : []),
    ]
    const newCategories = newChecked
      ? [...currentCategories, ...ids]
      : currentCategories.filter(item => !ids.includes(item))

    setIsOpen(newChecked)
    updateURLParameters({ kategoria: newCategories })
  }

  function handleSubcategoryChange(subcategoryId, subcategoryChecked) {
    const newCategories = subcategoryChecked
      ? [...currentCategories, subcategoryId]
      : currentCategories.filter(item => item !== subcategoryId && item !== id)

    updateURLParameters({ kategoria: newCategories })
  }

  return (
    <>
      <div
        className="my-2 flex w-full cursor-pointer flex-row items-center justify-between hover:bg-gray-100"
        onClick={handleTitleClick}>
        <div className="flex">
          {subcategories ? (
            isOpen ? (
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="rotate(90)">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {' '}
                  <path
                    d="M10 7L15 12L10 17"
                    stroke="#000000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>{' '}
                </g>
              </svg>
            ) : (
              <svg
                width="32px"
                height="32px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="rotate(0)">
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  {' '}
                  <path
                    d="M10 7L15 12L10 17"
                    stroke="#000000"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"></path>{' '}
                </g>
              </svg>
            )
          ) : (
            <div className="ml-8" />
          )}
          <p>{title}</p>
        </div>

        <button
          className="h-[20px] w-[20px] flex-shrink-0"
          onClick={handleButtonClick}>
          <svg
            viewBox="-102.4 -102.4 1228.80 1228.80"
            className="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            transform="rotate(0)matrix(1, 0, 0, 1, 0, 0)"
            stroke="#000000"
            strokeWidth="0.01024">
            <g
              id="SVGRepo_bgCarrier"
              strokeWidth="0"
              transform="translate(15.360000000000014,15.360000000000014), scale(0.97)">
              <rect
                x="-102.4"
                y="-102.4"
                width="1228.80"
                height="1228.80"
                rx="614.4"
                fill="#ffffff"
                stroke="#000000"
                strokeWidth="10"></rect>
            </g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
              stroke="#CCCCCC"
              strokeWidth="4.096"></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M866.133333 258.133333L362.666667 761.6l-204.8-204.8L98.133333 618.666667 362.666667 881.066667l563.2-563.2z"
                fill={getColor(id, currentCategories, subcategories)}></path>
            </g>
          </svg>
        </button>
      </div>
      {subcategories && (
        <div className={classNames('ml-10', { hidden: !isOpen })}>
          {subcategories.map(subcategory => {
            return (
              <SubCategory
                id={subcategory._id}
                maxLength={subcategories?.length}
                title={subcategory.name}
                isChecked={
                  isChecked || isIdPartOfUrl(subcategory._id, currentCategories)
                }
                onChange={handleSubcategoryChange}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export default Category
