import React, { memo, useState } from 'react'
import randomImg from '../../images/IMG_9813.jpg'
const ProductItem = ({ item }) => {
  //removing mb-24 from max div

  /*
   *colors:      string[];
   *image:       string[];
   *_id:         string;
   *name:        string;
   *category1:   Category1;
   *category2:   Category2;
   *category3:   null | string;
   *category4:   null;
   *category5:   null;
   *material:    string;
   *description: string;
   *barcode:     string;
   *origin:      null;
   *price:       string;
   *__v:         number;
   */

  const [currentSlide, setCurrentSlide] = useState(0)

  const previousSlide = () => {
    if (currentSlide === 0) setCurrentSlide(slides.length - 1)
    else setCurrentSlide(currentSlide - 1)
  }

  const nextSlide = () => {
    if (currentSlide === slides.length - 1) setCurrentSlide(0)
    else setCurrentSlide(currentSlide + 1)
  }

  const slides = item.image
  return (
    <div className="relative flex h-[30rem] flex-col items-center justify-evenly overflow-hidden border-2 shadow-md md:h-[25rem] lg:shadow-xl xl:h-[30rem]">
      <div className="flex max-h-full max-w-full flex-[4] items-center justify-center overflow-hidden">
        <img
          loading="lazy"
          key={'image' + currentSlide}
          src={
            item.image[0]
              ? require(`../../images/${slides[currentSlide]}`)
              : randomImg
          }
          alt="Something happened, could not be found"
          className="object-cover"
        />
      </div>

      {slides.length > 1 && (
        <div className="absolute top-0 flex h-full w-full items-center justify-between text-3xl text-white">
          <button onClick={previousSlide} className="h-full mix-blend-darken">
            <p className="text-7xl text-gray-600">&lsaquo;</p>
          </button>
          <button onClick={nextSlide} className="h-full mix-blend-darken">
            <p className="text-7xl text-gray-600">&rsaquo;</p>
          </button>
        </div>
      )}

      <div className="flex w-full flex-[1] items-center justify-center border-t-2 px-2 ">
        <p className="line-clamp-2 break-words text-xl font-semibold sm:text-lg">
          {item.name}
        </p>
      </div>
    </div>
  )
}

export default memo(ProductItem)
