import React, { useState } from 'react'
import AllProducts from '../components/productsComponents/AllProducts'
import { FilterBy } from '../components/productsComponents/FilterBy'

const ProductsPage = () => {
  const [revealFilters, setRevealFilters] = useState(false)
  return (
    <div className="mx-2 mt-1 flex h-full flex-col px-10 lg:flex-row lg:gap-10 xl:mx-7 xl:gap-5">
      <button
        className={
          'mb-3 h-12 w-full border-2 bg-white text-2xl font-semibold shadow-md lg:hidden'
        }
        onClick={() => setRevealFilters(e => !e)}>
        {revealFilters ? `Hiq filterat` : `Shfaq filterat`}
      </button>
      <FilterBy
        className={{
          hidden: revealFilters === false,
          'flex h-fit flex-wrap bg-white': revealFilters === true,
        }}
      />
      <AllProducts />
    </div>
  )
}

export default ProductsPage
