import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { NavBar } from './navigators/NavBar'
import About from './screens/About'
import Home from './screens/Home'
import ProductsPage from './screens/ProductsPage'
function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductsPage />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  )
}

export default App
