import React, { useMemo, useState } from 'react'
import ProductItem from './ProductItem'
import classNames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'

const AllProducts = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(50)

  const [queryParams] = useSearchParams()
  const filtersList = useMemo(() => {
    return {
      materials: queryParams.get('materiali')
        ? queryParams.get('materiali').split(',')
        : [],
      colors: queryParams.get('ngjyra')
        ? queryParams.get('ngjyra').split(',')
        : [],
      categories: queryParams.get('kategoria')
        ? queryParams.get('kategoria').split(',')
        : [],
      query: queryParams.get('q') || '',
    }
  }, [queryParams])
  const { data: categoryInstancesData } = useFetch(
    '/catalog/filtered_products',
    {
      ...filtersList,
      page: currentPage ?? 1,
      limit: itemsPerPage ?? 50,
    },
  )

  const products = categoryInstancesData?.categoryinstance_list ?? []
  const totalProducts = categoryInstancesData?.totalCount

  const totalPages = Math.ceil(totalProducts / itemsPerPage)

  return (
    <div className="flex w-full flex-col">
      <div className="sticky top-0 z-20 mb-2 flex flex-wrap justify-between gap-2 border-b-2 border-gray-200 bg-white p-2 text-lg">
        <div className="flex flex-wrap items-center gap-1 md:gap-3">
          <button
            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 text-3xl text-white hover:bg-gray-400"
            onClick={() => setCurrentPage(prevPage => prevPage - 1)}
            disabled={currentPage === 1}>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z"
                  fill="#FFFFFF"></path>{' '}
              </g>
            </svg>
          </button>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            page => {
              if (
                (page <= currentPage + 1 && page >= currentPage - 1) ||
                page === totalPages ||
                page === 1 ||
                (currentPage < 4 && page <= 4) ||
                (currentPage > totalPages - 3 && page >= totalPages - 3)
              )
                return (
                  <button
                    className={classNames(
                      'h-10 w-10 rounded-full text-lg font-semibold',
                      {
                        'bg-gray-200 text-black hover:bg-gray-200 ':
                          page === currentPage,
                        'bg-gray-500 text-white hover:bg-gray-400':
                          page !== currentPage,
                      },
                    )}
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    disabled={page === currentPage}>
                    {page}
                  </button>
                )
              else return <span key={page + 'dot'}>.</span>
            },
          )}
          <button
            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 text-3xl text-white"
            onClick={() => setCurrentPage(prevPage => prevPage + 1)}
            disabled={currentPage === totalPages}>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z"
                  fill="#FFFFFF"></path>{' '}
              </g>
            </svg>
          </button>
        </div>
        <div className="hidden items-center lg:flex">
          <label htmlFor="items-per-page" className="font-semibold">
            Shfaq
          </label>
          <select
            id="items-per-page"
            className="ml-3 cursor-pointer border bg-slate-100 p-2 font-medium"
            value={itemsPerPage}
            onChange={e => {
              setCurrentPage(1)
              setItemsPerPage(Number(e.target.value))
            }}>
            <option value="50">50 produkte / faqe</option>
            <option value="75">75 produkte / faqe</option>
            <option value="100">100 produkte / faqe</option>
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-8 lg:grid-cols-3 lg:gap-7 xl:grid-cols-3 xl:gap-7 2xl:grid-cols-4">
        {products.map(product => {
          return (
            <div key={product._id}>
              <ProductItem item={product} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(AllProducts)
