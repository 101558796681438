import React, { useState, useEffect } from 'react'
import plane1 from '../../images/homepage_image_1.jpeg'
import plane2 from '../../images/homepage_image_3.jpg'
import plane3 from '../../images/homepage_image_4.jpg'
import plane4 from '../../images/homepage_image_5.jpg'
import { useNavigate } from 'react-router-dom';

const HomeCarousel = () => {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/products'); // Navigate to the About page
  };

  const [currentSlide, setCurrentSlide] = useState(0)

  const previousSlide = () => {
    if (currentSlide === 0) setCurrentSlide(slides.length - 1)
    else setCurrentSlide(currentSlide - 1)
  }

  const nextSlide = () => {
    if (currentSlide === slides.length - 1) setCurrentSlide(0)
    else setCurrentSlide(currentSlide + 1)
  }

  const slides = [plane1, plane2, plane3, plane4]

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 4000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [slides.length]);

  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen py-10">
       {/* CTA Button */}
       <div className="mb-8 text-4xl lg:text-6xl font-bold text-gray-800">
        <button onClick={handleButtonClick} className="px-8 py-4 text-gray text-lg rounded-xl shadow-lg hover:bg-slate-800 hover:shadow-2xl hover:text-white transition-all">
          Exploro Produktet
        </button>
      </div>
      
      {/*<h2 className="mb-8 text-4xl lg:text-6xl font-bold text-gray-800">Produktet</h2> */}
      {/* Carousel Container */}
      <div className="relative w-full xl:w-9/12 overflow-hidden shadow-lg rounded-lg bg-white aspect-w-16 aspect-h-9">
        <div
          className={`flex transition-transform duration-700 ease-in-out`}
          style={{
            transform: `translateX(-${currentSlide * 100}%)`,
          }}>
          {slides.map((s, index) => {
            return <img key={'image' + index} src={s} alt={`Slide ${index + 1}`}  className="w-full h-full object-cover" style={{ flex: '0 0 100%' }} // Make each image take 100% of the container's width
            />
          })}
        </div>
        <div className="absolute top-0 flex h-full w-full items-center justify-between text-3xl text-white">
          <button
            onClick={previousSlide}
            className="h-full bg-transparent px-10">
            <p className="text-7xl">&lsaquo;</p>
          </button>
          <button onClick={nextSlide} className="h-full bg-transparent px-10">
            <p className="text-7xl">&rsaquo;</p>
          </button>
        </div>

                {/* Dots Indicator */}
                <div className="absolute bottom-0 flex justify-center gap-2 py-4 w-full">
          {slides.map((_, i) => (
            <div
              key={'circle' + i}
              onClick={() => setCurrentSlide(i)}
              className={`h-4 w-4 cursor-pointer rounded-full transition-all ${
                i === currentSlide ? 'bg-gray-700' : 'bg-gray-400'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default HomeCarousel
