import React, { useState } from 'react'
import Category from './Category'
import { CustomDropdown } from '../CustomDropdown'
import classNames from 'classnames'
import { useUpdateURLParameters } from '../../hooks/useUpdateURLParams'
import { getModifiedCategories } from './_helpers'
import useFetch from '../../hooks/useFetch'

const SearchInput = () => {
  const { currentQuery, updateURLParameters } = useUpdateURLParameters()
  const [input, setInput] = useState(currentQuery)
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        updateURLParameters({
          q: input,
        })
      }}>
      <div className="z-20 flex text-base text-gray-800 ">
        <div className="relative z-20 flex w-full border py-3 text-gray-600 shadow-md focus-within:text-gray-400">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="p-1 outline-none">
              <svg
                fill="none"
                stroke="#aaaaaa"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                className="h-6 w-6">
                <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </span>
          {input && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-2">
              <button
                type="button"
                className="focus:shadow-outline p-1 focus:outline-none"
                onClick={() => {
                  updateURLParameters({
                    q: '',
                  })
                  setInput('')
                }}>
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="h-5 w-5">
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </span>
          )}
          <input
            type="search"
            name="q"
            className="rounded-lg pl-10 text-base text-gray-900 focus:bg-white focus:text-gray-900 focus:outline-none"
            placeholder="Kërko..."
            autoComplete="off"
            value={input}
            onChange={event => setInput(event.target.value)}
          />
        </div>
      </div>
    </form>
  )
}

export const FilterBy = ({ className }) => {
  const { data: colorsData } = useFetch('/catalog/colors')
  const { data: materialsData } = useFetch('/catalog/materials')
  const { data: categoriesData } = useFetch('/catalog/categories')
  const colors = colorsData?.color_list ?? []
  const materials = materialsData?.material_list ?? []
  const categories = categoriesData?.data ?? []

  const modifiedCategories = getModifiedCategories(categories)
  const [openDropdown, setOpenDropdown] = useState(null)
  return (
    <div
      className={classNames(
        'border-gray-200 text-lg md:gap-12 lg:sticky lg:top-0 lg:block lg:w-2/5 lg:self-start lg:overflow-y-auto lg:px-5 2xl:w-1/4',
        className,
      )}>
      <div className="w-full md:w-2/5 lg:w-full">
        <p className="inline-block whitespace-nowrap py-2 text-xl font-bold uppercase leading-relaxed">
          TE PERGJITHSHME
        </p>
        <SearchInput />
        <CustomDropdown
          data={colors}
          title={'Ngjyra'}
          isOpen={openDropdown === 'Ngjyra'}
          setOpen={setOpenDropdown}
        />
        <CustomDropdown
          data={materials}
          title={'Materiali'}
          isOpen={openDropdown === 'Materiali'}
          setOpen={setOpenDropdown}
        />
      </div>
      <div className="w-full md:w-1/2 lg:w-full">
        <p className="inline-block whitespace-nowrap py-2 text-xl font-bold uppercase leading-relaxed">
          ARTIKUJ
        </p>
        {modifiedCategories.map(category => {
          return (
            <Category
              id={category._id}
              name={category.name}
              subcategories={category.subcategories}
              title={category.name}
              key={category._id}
            />
          )
        })}
      </div>
    </div>
  )
}
