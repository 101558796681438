import classNames from 'classnames'
import { useUpdateURLParameters } from '../hooks/useUpdateURLParams'

export const CustomDropdown = ({ data, title, isOpen, setOpen }) => {
  const { currentColors, currentMaterials, updateURLParameters } =
    useUpdateURLParameters()

  const getItems = () => {
    const items = title === 'Ngjyra' ? currentColors : currentMaterials
    return items || []
  }

  const items = data || []

  const toggleDropdown = () => setOpen(isOpen ? null : title)

  const handleItemClick = id => {
    const isSelected = getItems().includes(id)
    const newSelectedItems = isSelected
      ? getItems().filter(item => item !== id)
      : [...getItems(), id]
    updateURLParameters({
      [title.toLowerCase()]: newSelectedItems,
    })
  }
  return (
    <div className="relative my-2 flex w-full text-base text-gray-800">
      <div
        className={classNames(`w-full border bg-white shadow-md`, {
          'z-50': isOpen,
          'z-20': !isOpen,
        })}>
        <div
          className="flex cursor-pointer items-center justify-between px-5 py-3"
          onClick={toggleDropdown}>
          {getItems().length > 0
            ? getItems()
                .map(id => items.find(item => item._id === id).name)
                .join(', ')
            : title}
          {isOpen ? (
            <svg
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(90)">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  d="M10 7L15 12L10 17"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
              </g>
            </svg>
          ) : (
            <svg
              width="32px"
              height="32px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              transform="rotate(0)">
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                  d="M10 7L15 12L10 17"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"></path>{' '}
              </g>
            </svg>
          )}
        </div>
        <div
          className={classNames('w-full cursor-pointer', {
            'absolute z-50 h-64 overflow-y-scroll border-b border-l border-r':
              isOpen,
            hidden: !isOpen,
          })}>
          {items.map(item => (
            <div
              className="flex items-center border-t-2 bg-white p-4 shadow-md hover:bg-slate-100 "
              onClick={() => handleItemClick(item._id)}
              key={item._id}>
              <span
                className={`mr-2 text-blue-500 opacity-0 transition-all duration-200 ${
                  getItems().includes(item._id) && 'opacity-100'
                }`}>
                •{' '}
              </span>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
